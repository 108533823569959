const flipsFields = [
    {label: "AIO Retail US", dataField: "aio_retail_us"},
  {label: "Ticketmaster US", dataField: "ticketmasterUS"},
  {label: "Ticketmaster SG", dataField: "ticketmastersg"},
  {label: "Ticketmaster AU", dataField: "ticketmasterAU"},
  {label: "Ticketmaster NZ", dataField: "ticketmasterNZ"},
  {label: "Ticketmaster EU", dataField: "ticketmasterUK"},
  {label: "Ticketmaster CA", dataField: "ticketmasterca"},
  {label: "Ticketek AU", dataField: "ticketekau"},
  {label: "Ticketek AU Marketplace", dataField: "ticketek_au_marketplace"},
  {label: "Ticketek NZ", dataField: "ticketeknz"},
  {label: "Ticket Era", dataField: "ticketera"},
  {label: "TheConcert TH", dataField: "theconcert_th"},
  {label: "Tixel AU", dataField: "tixel_au"},
  {label: "Moshtix AU", dataField: "moshtix_au"},
  {label: "Pokemon Global", dataField: "pokemon_global"},
  {label: "City Line", dataField: "cityline"},
  {label: "Apple Instore (US)", dataField: "appleus"},
  { label: "Apple Instore (US) 2 (Extra Webhook for Ratelimits)", dataField: "appleus2"},

  { label: "Apple Instore (JP)", dataField: "applejp"},
  { label: "Apple Instore (JP) 2 (Extra Webhook for Ratelimits)", dataField: "applejp2"},
     { label: "Apple Instore (KR)", dataField: "applekr"},
     {label: "Apple Instore (AU)", dataField: "appleau"},
  {label: "Book My Show MY", dataField: "bookmyshow"},
  {label: "Go Live Asia & Thai Ticket Major MY", dataField: "goliveasia"},
  {label: "AXS Tickets", dataField: "axs"},
  {label: "SeatGeek US", dataField: "seatgeek"},
  {label: "Melon Ticket", dataField: "melon_ticket"},
  {label: "Nintendo Museum Ticketing", dataField: "nintendo_museum"},
  {label: "Bestbuy US", dataField: "bestbuy"},
      {label: "Bestbuy CA", dataField: "bestbuyCa"},
      {label: "Newegg Deals", dataField: "newegg_deals"},
      {label: "Amazon Deals/Price Errors", dataField: "amazon_bricks"},
      {label: "Amazon Deals/Price Errors 70%-100%", dataField: "amazon_bricks_70"},
      {label: "Amazon JP Deals/Price Errors", dataField: "amazonjp_deals"},


      {label: "Macys Deals (All)", dataField: "macys_deals"},
      {label: "Macys Deals (86%-100%)", dataField: "macys_deals_85_100"},
      {label: "Macys Deals (76%-85%)", dataField: "macys_deals_75_85"},
      {label: "Macys Deals (50%-75%)", dataField: "macys_deals_50_75"},


      {label: "Woot Deals", dataField: "woot_deals"},
      {label: "JB Hi-Fi Deals", dataField: "jbhifi_deals"},
 // {label: "Bestbuy In-Store", dataField: "bestbuyinstore"},
  {label: "Fanatics", dataField: "fanatics"},
  {label: "PS Direct", dataField: "psdirect"},
  {label: "AU Post", dataField: "au_post"},
  {label: "KMart AU", dataField: "kmart_au"},
  {label: "AU Food Deals", dataField: "au_food_deals"},
  {label: "Lego CA", dataField: "lego_ca"},
  {label: "Prospect Stamps AU", dataField: "au_prospect"},
  {label: "Target US Restocks (Filtered)", dataField: "target"},
  {label: "Target US New", dataField: "targetnew"},
  {label: "Target US Pickup", dataField: "target_pickup"},
  {label: "Costco US", dataField: "costco"},
  {label: "Samsclub", dataField: "samsclub"},
  {label: "Shop My Exchange", dataField: "shopmyexchange"},

  {label: "Target JP", dataField: "targetjp"},
  {label: "Target AU", dataField: "targetau"},
  {label: "Target AU Instore", dataField: "targetau_instore"},
  {label: "Home Depot", dataField: "homedepot"},
  {label: "Walmart US", dataField: "walmart"},
  {label: "Walmart US (Third Party)", dataField: "walmart_thirdparty"},
  {label: "Panini", dataField: "panini"},
  {label: "Funko US", dataField: "funko"},
  {label: "Hot Topic", dataField: "hottopic"},
  {label: "Box Lunch", dataField: "boxlunch"},
  {label: "Gamestop", dataField: "gamestop"},
  {label: "Topps US", dataField: "topps"},
  {label: "Topps JP", dataField: "topps_jp"},
  {label: "Newegg", dataField: "newegg"},
  {label: "Crunchy Roll", dataField: "crunchyroll"},
 
  {label: "Barnes And Nobles", dataField: "barnes_nobles"},
  {label: "Lazada MY Popmart", dataField: "lazada"},
  {label: "Aliexpress Popmart", dataField: "aliexpress_popmart"},
  {label: "Aliexpress EU/UK Popmart", dataField: "aliexpress_eu"},
  {label: "Lazada TH Popmart", dataField: "lazada_th"},
  {label: "Lazada VN Popmart", dataField: "lazada_vn"},
  {label: "Lazada SG Popmart", dataField: "lazada_sg"},
  {label: "Lazada PH Popmart", dataField: "lazada_ph"},
  {label: "Lazada Sales", dataField: "lazada_sales"},
  {label: "Amazon US", dataField: "amazon"},
  {label: "Amazon US Popmart", dataField: "amazon_popmart"},
  {label: "Amazon US Pokemon", dataField: "amazon_pokemon"},
  {label: "Amazon AU", dataField: "amazon_au"},
  {label: "Amazon UK", dataField: "amazon_uk"},
  {label: "Sony AU", dataField: "sonyau"},
  {label: "Gamesmen AU", dataField: "gamesmen"},
  {label: "EbGames AU", dataField: "ebgamesau"},
  {label: "Tesla", dataField: "tesla"},
  {label: "Kelz0r DK", dataField: "kelz0r"},
  {label: "Rare Candy Pokemon", dataField: "rarecandy"},

  {label: "David Jones Pokemon", dataField: "davidjones"},
  {label: "John Lewis Pokemon", dataField: "johnlewis"},
  {label: "Loot Cave DE Pokemon", dataField: "lootcave"},
  {label: "Zaavi UK Pokemon", dataField: "zaavi"},
  {label: "Zephyr Epic Pokemon", dataField: "zephyrepic"},
  {label: "Zatu Games Pokemon", dataField: "zatugames"},
  {label: "London Drug Pokemon", dataField: "londondrugs"},

  {label: "Myer AU Pokemon", dataField: "myer_au"},
  {label: "Popcultcha AU", dataField: "popcultcha"},

  {label: "Costco AU Pokemon", dataField: "costcoau"},
  {label: "Table Top Republic Pokemon", dataField: "tabletoprepublic"},
  {label: "Self Ridges Pokemon", dataField: "selfridges_pokemon"},
  {label: "Toymate AU Pokemon", dataField: "toymateau"},
  {label: "Very.co.uk Pokemon", dataField: "veryuk"},
  {label: "Face To Face Games", dataField: "facetoface"},
  {label: "HMV UK", dataField: "hmvuk"},
  {label: "OzBargain Pokemon", dataField: "ozbargain_pokemon"},
  {label: "Big W AU", dataField: "bigwau"},
  {label: "Ryft AU", dataField: "ryftau"},
  {label: "Canon UK", dataField: "canon_uk"},
  {label: "Hermes US", dataField: "hermes_us"},
 
  {label: "Hermes MY", dataField: "hermes_my"},
  {label: "Disney Store US", dataField: "disneystore"},
  {label: "Disney Store AU", dataField: "disneystore_au"},
  {label: "Taylor Made", dataField: "taylor_made"},
  {label: "Pokemon Center US", dataField: "pokemoncenter"},
  {label: "Pokemon Center AU", dataField: "pokemoncenter_au"},
      {label: "Scheels", dataField: "scheels"},
      {label: "Books A Million", dataField: "booksamillion"},
  {label: "Dicks Sporting Goods Flips", dataField: "dsgflips"},
  {label: "Five Below", dataField: "fivebelow"},
  {label: "Hasbro Pulse", dataField: "hasbro"},
  {label: "Yellow Submarine", dataField: "yellow_submarine"},
  {label: "Ninja Kitchen", dataField: "ninja_kitchen"},
  {label: "Ace Hardware", dataField: "acehardware"},
  {label: "The Canvas Don", dataField: "thecanvasdon"},
  {label: "Toys R Us (MY)", dataField: "toysrus_my"},
  { label: "Popmart Launches", dataField: "popmart_launches"},
  { label: "Popmart Thailand", dataField: "popmartth"},
  {label: "Popmart US", dataField: "popmartus"},
  {label: "Popmart US 2 (Extra Webhook for Ratelimits)", dataField: "popmartus2"},
  {label: "Popmart US In-Store", dataField: "popmart_us_instore"},
  {label: "Popmart MY", dataField: "popmartmy"},
  {label: "Popmart HK", dataField: "popmarthk"},
  {label: "Popmart MO", dataField: "popmartmo"},
  {label: "Popmart KR", dataField: "popmartkr"},
  {label: "Popmart TW", dataField: "popmarttw"},
  {label: "Popmart VN", dataField: "popmartvn"},
  {label: "Popmart PH", dataField: "popmartph"},
  {label: "Popmart GB", dataField: "popmartgb"},
  {label: "Popmart DE", dataField: "popmartde"},
  {label: "Popmart CA", dataField: "popmartca"},
  {label: "Popmart AU (popmart.com)", dataField: "popmart_au"},
  {label: "Popmart NZ (popmart.com)", dataField: "popmart_nz"},
  {label: "Popmart SG (popmart.com)", dataField: "popmart_sg"},
  {label: "Popmart JP (popmart.com)", dataField: "popmartjp_com"},
  {label: "Popmart.co.jp (JP)", dataField: "popmartjp"},
  {label: "Smart Store Apple KR", dataField: "apple_smartstore"},
  {label: "Smart Store Popmart KR", dataField: "popmart_smartstore"},
      {label: "Amazon JP", dataField: "amazonjp"},
     { label: "Yahoo JP", dataField: "yahoojp"},
     { label: "Microsoft JP", dataField: "microsoft"},
     { label: "Geo Mobile (JP)", dataField: "geomobile"},
     {label: "Murauchi (JP)", dataField: "murauchi"},
     {label: "Hikari TV (JP)", dataField: "hikaritv"},
     { label: "Haswega (JP)", dataField: "haswega"},
    
     { label: "Daimaru Matsuzakaya (JP)", dataField: "daimaru"},
     {label: "Dod Camp (JP)", dataField: "dodcamp"},
     {label: "Fujifilm Mall (JP)", dataField: "fujifilm_mall"},
      {label: "Pokemon Center JP Filtered", dataField: "pokemoncenter-online"},
      {label: "Pokemon Center JP Unfiltered", dataField: "pokemoncenter-online_new"},
          {label: "Rakuten (JP)", dataField: "rakuten"},
          {label: "Rakuten Filtered (JP)", dataField: "rakuten_filtered"},
          {label: "Workman (JP)", dataField: "workmanjp"},
          {label: "Soph.net (JP)", dataField: "sophnet"},
          {label: "IQOS (JP)", dataField: "iqos"},
          {label: "Shueisha (JP)", dataField: "shueisha"},
          {label: "Keionet (JP)", dataField: "keionet"},
          {label: "Gurapan (JP)", dataField: "gurapan"},
          {label: "Furu1 (JP)", dataField: "furu1"},
          {label: "Lohaco (JP)", dataField: "lohaco"},
          {label: "Kitamura (JP)", dataField: "kitamura"},
          {label: "MTG (JP)", dataField: "mtg"},
          {label: "Chocosan (JP)", dataField: "daikou"},
          {label: "Botanize (JP)", dataField: "botanize"},
          {label: "Katachiseisakujyo (JP)", dataField: "katachiseisakujyo"},
          {label: "Sony (JP)", dataField: "sonyjp"},
          {label: "IrisPlaza (JP)", dataField: "irisplaza"},
          {label: "Saison (JP)", dataField: "saisoncard"},
          {label: "Line Friends (JP)", dataField: "linefriendsjp"},
          {label: "Fujiya Camera (JP)", dataField: "fujiya_camera"},
          {label: "Camera No Ohbayashi (JP)", dataField: "camera_ohbayashi"},

          {label: "Aeon Retail (JP)", dataField: "aeonretail"},
          {label: "Aeon Instore (JP)", dataField: "aeoninstore"},
          {label: "Card Shop Serra (JP)", dataField: "cardshop_serra"},
          {label: "Cherumoweb (JP)", dataField: "cherumoweb"},
          {label: "Mintmall (JP)", dataField: "mintmall"},
          {label: "Takaratomymall (JP)", dataField: "takaratomymall"},
          {label: "Seibu Sogo (JP)", dataField: "seibu"},
          {label: "Tsutaya (JP)", dataField: "tsutaya"},
          {label: "Gamers JP (JP)", dataField: "gamers_jp"},
          {label: "Ito-Yokado Online Shopping (JP)", dataField: "itoyokado"},
          {label: "Kinetsu (JP)", dataField: "kintetsu"},
          {label: "Tcg Academy (JP)", dataField: "tcgacademy"},
           {label: "Map Camera (JP)", dataField: "mapcamera"},
           {label: "Yamada Denkiweb (JP)", dataField: "yamada"},
               {label: "Sofmap (JP)", dataField: "sofmap"},
               {label: "Nojima (JP)", dataField: "nojima"},
       {label: "Amenity Dream (JP)", dataField: "amenitydream"},
       {label: "Cardshop Hanjou (JP)", dataField: "hanjou"},
           {label: "Big Web (JP)", dataField: "bigweb"},
          {label: "Nendohouse (JP)", dataField: "nendohouse"},
          {label: "Joshin (JP)", dataField: "joshin"},
          {label: "Geo Online (JP)", dataField: "geoonline"},
          {label: "Geo Online Instore (JP)", dataField: "geo_online_instore"},
          {label: "Kojima (JP)", dataField: "kojima"},
          {label: "Edion (JP)", dataField: "edion"},
          {label: "Loft Netstore (JP)", dataField: "loftnet"},
          {label: "Amiami (JP)", dataField: "amiami"},
          {label: "NTTX (JP)", dataField: "nttx"},
                  {label: "Sekiguchi (JP)", dataField: "sekiguchi"},
          {label: "Toys R Us (JP)", dataField: "toysrusjp"},
          {label: "Odakyu (JP)", dataField: "odakyu"},
          {label: "CJ Mart (JP)", dataField: "cjmart"},
          {label: "カードショップ紀州 (JP)", dataField: "cardshopkishu"},
          {label: "Hobby Link (JP)", dataField: "hobbylink"},
          {label: "Card Max (JP)", dataField: "cardmaxjp"},
          {label: "Dragon Star (JP)", dataField: "dorasuta"},
          {label: "Komodo (JP)", dataField: "komodojp"},
          {label: "Happinet Online (JP)", dataField: "happinetonline"},
          {label: "Mana Source (JP)", dataField: "manasource"},
          {label: "KsDenki (JP)", dataField: "ksdenki"},

          {label: "Pao Online Shop (JP)", dataField: "pao_shop"},
          {label: "Sylvanian Families (JP)", dataField: "sylvanianfamilies"},
          { label: "Dreampocket Webshop (JP)", dataField: "dreampocket_webshop"},
          {label: "Advantage TCG (JP)", dataField: "advantagecg"},
          {label: "Planet Pocket (JP)", dataField: "planetpocketjp"},
          {label: "BicCamera (JP)", dataField: "biccamera"},
          {label: "Shimamura (JP)", dataField: "shimamura"},
          {label: "Yuurakusya (JP)", dataField: "yuurakusya"},
          {label: "7Net (JP)", dataField: "7net"},
          {label: "FullAhead (JP)", dataField: "fullahead"},
          {label: "Fullcomp Akihabara (JP)", dataField: "fullcomp_akihabara"},
          {label: "Ryuunoshippo (JP)", dataField: "ryuunoshippo"},
          {label: "Yamagen Camera (JP)", dataField: "yamagen_camera"},
          {label: "Sol Works (JP)", dataField: "solworks"},
          {label: "Asimo Crafts (JP)", dataField: "asimocrafts"},
          {label: "Geek Field (JP)", dataField: "geekfield"},
          {label: "3ZO (JP)", dataField: "3zo"},
          {label: "Project Core (JP)", dataField: "projectcorejp"},
          {label: "C-Labo (JP)", dataField: "clabo"},
          {label: "Masters Square (JP)", dataField: "masters_square"},
       {label: "Yodobashi (JP)", dataField: "yodobashi"},

       {label: "Tttmsw (JP)", dataField: "tttmsw"},
       {label: "Otsukalotec (JP)", dataField: "otsukalotec"},
       {label: "Mottokuma (JP)", dataField: "mottokuma"},
       {label: "Camp Geeks (JP)", dataField: "campgeeks"},
       {label: "Nuts Outdoor (JP)", dataField: "nuts_outdoor"},
       {label: "Sol Products (JP)", dataField: "solproducts"},
       {label: "Spicy Gem (JP)", dataField: "spicygem"},
       {label: "Shim Craft (JP)", dataField: "shimcraft"},
       {label: "Outdoor Mills (JP)", dataField: "outdoormils"},
       {label: "NM Monkeys (JP)", dataField: "nmmonkeys"},
       {label: "CT Flag Store (JP)", dataField: "ctct_thebase"}
];

export default flipsFields;
